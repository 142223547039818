.table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; /* Align the table to the left */
}

/* Rest of your CSS remains the same */

.table-row {
  display: flex;
  justify-content: space-between;
  width: 300px; /* Adjust the width as needed */
  margin-bottom: 10px;
}

.table-header {
  font-weight: bold;
}

.table-cell {
  flex: 1;
  text-align: center;
  border: 1px solid #ddd;
  padding: 5px;
  margin: 0 5px;
}
.video-container {
 
  display: flex;
  flex-direction: column; /* Stack videos vertically */
  align-items: center; /* Center videos horizontally */
  gap: 10px; /* Add space between videos */
}
.videocard{
  align-items: center;
  justify-content: center;
  border-radius: 30%;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .video-container {
    margin-top: 0px;
    flex-direction: column; /* Stack videos vertically */
    align-items: center; /* Center videos horizontally */
    gap: 0;
    margin-top: -150px;
  }
  .categorybayanh1{
    color: white;
  }
  .hwh1{
    font-size: 32px;
    position: relative;
    top: 100px;
    margin-top: 75px;
  }
}
.hwh1{
  text-align: center;
}
.static-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #1e20a3; /* Adjust this to the desired color */
  width: 170px; /* Adjust the width to accommodate text */
  height: 50px;
  border-radius: 25px; /* Adjust to make it round */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999; /* Ensure the icon stays on top of other content */
}
.static-icon1 {
  position: fixed;
  bottom: 80px;
  left: 20px;
  background-color: #0a0b5b; /* Adjust this to the desired color */
  width: 170px; /* Argb(2, 138, 127)t the width to accommodate text */
  height: 50px;
  border-radius: 25px; /* Adjust to make it round */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999; /* Ensure the icon stays on top of other content */
}

.icon-text {
  color: #ffffff; /* Adjust the color of the text */
  font-size: 16px; /* Adjust the font size of the text */
  margin-right: 5px;
  padding-bottom: 20px;
  /* Adjust the spacing between the text and icon */
}

.static-icon i {
  margin-top: 1px;
  color: #e1e1e1; /* Adjust the color of the icon */
  font-size: 20px; /* Adjust the size of the icon */
}
.static-icon1 i {
  margin-bottom: 4px;
  color: #fffdfd; /* Adjust the color of the icon */
  font-size: 24px; /* Adjust the size of the icon */
}
