.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
  
  .modal-container {
    position: relative; /* Position relative for absolute positioning of cross icon */
    background-color: rgb(255, 255, 255);
    width: 40%;
    height: 70%;
    padding: 20px;
    margin-top: 160px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .modal-title {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; /* Prevent wrapping of title */
    margin: 0; /* Remove default margin */
    font-size: 25px;
  }
  
  .modal-image {
    width: 60%;
    height: 95%; /* Maintain aspect ratio */
  }
  
  .modal-close-icon {
    position: absolute;
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    cursor: pointer;
    font-size: 30px; /* Adjust icon size as needed */
    color: black; /* Adjust icon color as needed */
  }
  
  /* Media query for smaller height on mobile devices */
  @media (max-width: 768px) {
    .modal-container {
      height: 60%;
      width: 90%;
      margin-top: 60px;
    }
    .modal-image{
      width: 100%;
      margin-top: 15px;
      height: 80%;
    }
  }
  